import Glide from '@glidejs/glide'
import lottie from 'lottie-web'
const menuJson = require('../../web/wp-content/themes/bigboynew/menujson.json')
const mugJson = require('../../web/wp-content/themes/bigboynew/mugjson.json')
//Swappy word thing for frontpage
if(document.getElementById('slogans')) {
    const slogans = document.getElementsByClassName('slogan-item')
    const sloganArray = window.sloganArray
    for (let i = 0; i < slogans.length; ++i) {
        sloganArray.push(slogans[i].innerText)
    }
    textSequence(0)
    function textSequence(i) {
        if (sloganArray.length > i) {
            setTimeout(function() {
                document.getElementById("sequence").innerHTML = sloganArray[i]
                textSequence(++i)
            }, 2000)
        } else if (sloganArray.length == i) {
            textSequence(0)
        }
    }
}

if(document.getElementById('lunchToggle')) {

    const lunchToggle = document.getElementById('lunchToggle')
    const lunchList = document.getElementById('lunchList')
    lunchToggle.onclick = ()=> {
        if (lunchList.classList.contains('visible')) {
            lunchToggle.innerHTML = 'Se hela veckomenyn'
        } else {
            lunchToggle.innerHTML = 'Stäng'
        }
        lunchList.classList.toggle('visible')
    }

}
if(document.getElementById('timeline')) {
    const glide = new Glide('.glide', {
        type: 'carousel',
        perView: 3,
        rewind: false,
        breakpoints: {
            1024: {
                perView: 2
            },
            600: {
                perView: 1
            }
        }
    })
    glide.mount()
}

if(document.getElementById('vacancies')) {
    const filters = document.querySelectorAll('.js-filter-jobs')
    const jobs = document.querySelectorAll('.job')

    filters.forEach(filter => {
        const city = filter.dataset.city
        let numOfJobs = 0;
        if(city == 'all') {
            filter.append(`(${jobs.length})`)
        } else {
            jobs.forEach(job => {
                if(job.dataset.city == city) {
                    numOfJobs++
                }
            })
            filter.append(`(${numOfJobs})`)
        }
        if(numOfJobs < 1 && city != 'all') {
            filter.classList.add('inactive')
        }
        filter.onclick = () => {
            filters.forEach(filter => filter.classList.remove('active'))
            filter.classList.add('active')
            jobs.forEach(job => {
                job.style.display = 'none'
                if(job.dataset.city == city) {
                    job.style.display = 'block'
                }
                if(city == 'all') {
                    job.style.display = 'block'
                }
            })
        }
    })
    jobs.forEach(job => {
        job.onclick = () => {
            document.querySelectorAll('.job-description').forEach((desc) => desc.classList.remove('visible'))
            console.log(job.children)
            const children = job.children
            for (let i = 0; i < children.length; i++) {
                if(children[i].classList.contains('job-description')) {
                    // if (children[i].classList.contains('visible')) {
                    //     children[i +1].innerHTML = 'Läs mer'
                    // } else {
                    //     children[i +1].innerHTML = 'Stäng'
                    // }
                    children[i].classList.toggle('visible')
                }
            }
        }
    })
}

const menuAnim = document.getElementById('menuAnim')

const menuAnimation = lottie.loadAnimation({
    container: menuAnim, // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: false,
    animationData: menuJson // the path to the animation json
})


const menuToggle = document.getElementById('menuToggle')
const menu = document.getElementById('menu')
const menuSec = document.getElementById('menuSec');
const menuItems = document.getElementById('menuItems')
const logo = document.getElementById('logo')
const logoSec = document.getElementById('logoSec')
const burger = document.getElementById('burger')
menuToggle.onclick = () => {
    menu.classList.toggle('visible')
    menuItems.classList.toggle('visible')
    if(!menu.classList.contains('visible')) {
        menuAnimation.pause()
        logoSec.style.display = 'none'
        logo.style.display = 'block'
        setTimeout(() => {
            burgerSec.style.display = 'none'
            burger.style.display = 'block'
        }, 150)
    } else {
        menuAnimation.play()
        setTimeout(() => {
            logo.style.display = 'none'
            logoSec.style.display = 'block'
        }, 150)
        burger.style.display = 'none'
        burgerSec.style.display = 'block'
    }
}

const mugAnim = document.getElementById('mugAnim')
if(mugAnim) {
    const mugAnimation = lottie.loadAnimation({
        container: mugAnim, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: mugJson // the path to the animation json
    })
}

const foodToggles = document.querySelectorAll('.js-expand-food')
foodToggles.forEach(toggle => {
    toggle.onclick = function() {
        toggle.lastElementChild.classList.toggle('active')
    }
})